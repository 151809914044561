import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {FaMap ,FaXing ,FaGofore , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaXing /> , Text:'XING', link: 'https://www.xing.com/profile/Serkan_Uslubas'},
    {Social: <FaLinkedinIn />, Text:'LinkedIn', link: 'https://www.linkedin.com/in/serkan-uslubas-60783541/'},
    {Social: <FaMap />, Text:'freelancermap', link: 'https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/33809-profil-serkan-uslubas-softwareentwickler.html'},
    {Social: <FaGofore />, Text:'GULP' , link: 'https://www.gulp.de/gulp2/g/spezialisten/profil/uslubas'},
]

class About extends Component{
    render(){
        let title = 'Über mich';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Über mich' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Über mich'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-10.jpg" alt="About Images"/>
                                        <img className="w-100" src="/assets/images/about/mcp.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p>Ich bin Softwareentwickler seit 1998. Anfangs programmierte ich nebenberuflich mit PERL, PHP und Delphi.</p>
                                            <p>2004 bekam ich eine Festanstellung in diesem Bereich. Das war ein Traum für mich. Nun konnte ich meine Leidenschaft zum Beruf machen.</p>
                                            <p>2008 startete ich meine eigene Firma: “dotnet-factory”. Ich wollte mich auf diesen Bereich spezialisieren.</p>
                                            <p>Softwareentwickler zu sein heißt Abenteuer erleben. Durch meine Webinare und Trainings lasse ich andere daran teilhaben.</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Software Entwicklung</h3>
                                                    <p>Meine Hauptaufgabe besteht darin, Business Requirements zu analysieren, passende Lösungen zu finden und erfolgreich umzusetzen.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Webinare</h3>
                                                    <p>Profitieren Sie von meinen Erfahrungen für Ihre Projekte oder verbessern Sie Ihre Chancen auf dem Arbeitsmarkt. Webinare sind interaktiv, aktuell und variabel.</p>
                                                </div>
                                            </div>
                                            <div className="section-title">
                                                <br/>
                                                {/* Start Single Widget  */}
                                                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                                    <ul className="social-share social-style--2 color-black no-bullets">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><nobr><a href={`${val.link}`}  target="_blank">{val.Social}   &nbsp; &nbsp;&nbsp;&nbsp;{val.Text}</a></nobr></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* End Single Widget  */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About