import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">ERFAHRUNGEN - WEITERGEBEN</h2>
                                    <p>Software Development - Webinare & Workshops</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Software Entwicklung</h4>
                                                    
                                                    <p>Softwareentwicklung bildet das Kernstück unserer Dienstleistungen.</p> 
                                                    <p>Wir analysieren Business Requirements, entwickeln maßgeschneiderte Lösungen 
                                                        und setzen sie um mit besonderem Fokus auf:</p>
                                                    
                                                    
                                                    <ul className="liststyle">
                                                        <li>hohe Softwarequalität</li>
                                                        <li>optimale Performance</li>
                                                        <li>und höchste Sicherheit</li>
                                                    </ul>
                                                    <br/>
                                                    <p>Diese Arbeit ist von zentraler Bedeutung, da sie direktes Feedback vom Kunden darüber gibt, ob die Lösung erfolgreich ist oder nicht. Dabei beobachten wir Response-Zeiten, die Zufriedenheit der Operatoren und die Leistungsfähigkeit der Infrastruktur, um sicherzustellen, dass die Lösung den Bedürfnissen des Kunden entspricht.</p>

                                                    <p>Kurz gesagt: Softwareentwicklung ist der Dreh- und Angelpunkt für fast alles. Es fühlt sich an wie eine Bühne, auf der das Publikum applaudiert oder nicht.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Webinare</h4>
                                                    <p>Webinare bieten eine einzigartige Gelegenheit, um Wissen und Fähigkeiten in einer komfortablen und erschwinglichen Umgebung zu erwerben. </p>
                                                    <p>Egal, ob Sie sich weiterbilden, Ihre Karriere vorantreiben oder einfach Ihr Interesse an einem bestimmten Thema vertiefen möchten - Webinare sind eine zeitgemäße Möglichkeit, Neues zu lernen, und haben folgende Vorteile:</p>
                                                    <br/>
                                                    <ul className="liststyle">
                                                        <li>Interaktiv</li>
                                                        <li>Praxisorientiert</li>
                                                        <li>Flexibel</li>
                                                        <li>Individuell</li>
                                                        <li>Motivierend</li>
                                                        <li>Sparen Zeit und Kosten</li>
                                                    </ul>                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.jpg" alt="Service Images"/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;