import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ReferenceElement from "./references/ReferenceElement";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiCast />,
        title: 'Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiMonitor />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
]
class Development extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Software Entwicklung' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Software Entwicklung'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--30">
                                    <h2>Fachliche Schwerpunkte</h2>
                                    <p>Wir sind Experten für Softwareentwicklung in verschiedenen Bereichen. Unser Schwerpunkt liegt auf der Softwarearchitektur sowie der Entwicklung von Backend- und Frontend-Anwendungen. Wir verfügen über fundiertes Wissen und Erfahrung mit den neuesten Technologien und Frameworks.</p>
                                    <p>Zu unseren Spezialgebieten gehört Microsoft .NET Core mit C#, mit dem wir robuste und skalierbare Anwendungen erstellen können, die sich ideal für das Backend eignen. Wir verwenden eine modulare Architektur, bei der die Anwendungskernschicht keine Abhängigkeiten von anderen Schichten hat. Dadurch können wir die Anwendungslogik von der Benutzeroberfläche und der Infrastruktur trennen.</p>
                                    <p>Als Architekt und Berater unterstützen wir unsere Kunden bei der Planung und Umsetzung ihrer Anforderungen und Ziele. Wir erstellen professionelle Webanwendungen mit ASP.NET Core, die eine hohe Qualität und Funktionalität bieten. Wir nutzen auch moderne DevOps-Praktiken, um einen kontinuierlichen Integrations- und Bereitstellungsprozess zu gewährleisten.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Reference Element Area */}
                <ReferenceElement />
                {/* End Reference Element Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Development;