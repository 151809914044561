import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 92,
                countTitle: 'Einer Studie von Adobe aus dem Jahr 2019 zufolge tragen Schulungswebinare dazu bei, die Fähigkeiten und das Wissen von 92% der Befragten zu verbessern.',
            },
            {
                countNum : 67,
                countTitle: '67% der Befragten einer Umfrage des Training Magazines im Jahr 2020 gaben an, dass sie den Lernstoff in Schulungswebinaren besser verstehen als in anderen Formaten wie Texten, Grafiken oder Videos.',
            },
            {
                countNum : 86,
                countTitle: 'Laut einer Studie von ClickMeeting im Jahr 2020 sind Schulungswebinare eine effektive Methode, um Mitarbeiter in einem Unternehmen zu schulen, sagten 86% der Befragten.',
            },
        ];

        return(
            <Fragment>
                <h1 className="counter">Warum Webinare</h1>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;