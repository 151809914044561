import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiBarChart ,FiChevronUp, FiCheck, FiCalendar, FiWatch, FiTarget, FiClock } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CounterOne from "../elements/counters/CounterOne";


const ServiceListOne = [
    {
        icon: <FiLayers />,
        title: 'Vertiefung des Wissens',
        description: 'Durch die Teilnahme an GraphQL-Webinaren können Sie Ihr Wissen über die Technologie vertiefen und lernen, wie Sie sie in Ihren Projekten effektiv einsetzen können.'
    },
    {
        icon: <FiCast />,
        title: 'Praktische Anwendung',
        description: 'Die meisten Webinare bieten praktische Übungen und Beispiele, die es Ihnen ermöglichen, das Gelernte in die Praxis umzusetzen und Ihre Fähigkeiten zu verbessern.'
    },
    {
        icon: <FiUsers />,
        title: 'Networking-Möglichkeiten',
        description: 'Webinare bieten eine großartige Gelegenheit, um mit anderen Entwicklern und Experten in Kontakt zu treten, Fragen zu stellen und Ihr Netzwerk zu erweitern.'
    },
]



class Webinar extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Webinar' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Webinare & Workshops'}   />
                {/* End Breadcrump Area */}


            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                        <h4>Alle Webinare sind im .NET-Umfeld realisiert</h4>
                        </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <object height={80} data="/assets/images/webinar/GraphQL_Logo.svg" type="image/svg+xml">
                                                <img src="/assets/images/service/service-02.jpg" />
                                            </object>
                                            <h4 className="title">GraphQL Webinar</h4>
                                            <div className="pricing">
                                                <span className="price">49 </span>
                                                <span className="subtitle">EUR</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">

                                        <ul className="list-style--1">
                                                <li><FiCalendar /> Datum: SA, 04.03.2023</li>
                                                <li><FiWatch /> 11:00 - 12:00 Uhr</li>
                                                <li><FiClock /> Dauer: 1 Stunde</li>
                                                <li><FiBarChart /> Level = Anfänger</li>
                                                <li><FiTarget /> Zielgruppe = Entwickler, Software Architekte</li>                                                

                                            </ul> 
                                            <br/>
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Was ist GraphQL?</li>
                                                <li><FiCheck /> Welche Frameworks nutzen?</li>
                                                <li><FiCheck /> GraphQL Backend erstellen</li>
                                                <li><FiCheck /> GraphQL abfragen</li>
                                                <li><FiCheck /> Blazor Frontend (UI)</li>
                                                <li><FiCheck /> Filtering, Paging, Sorting im UI</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-header">
                                            <h4 className="title">Speaker</h4>
                                            <img className="w-600" src="/assets/images/webinar/serkan-rounded.png" alt="Speaker"/>                                            
                                            <div className="pricing">
                                                <span className="subtitle"><a href="/about">Serkan Uslubas</a></span>
                                            </div>                                            
                                        </div>
                                        
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://register.gotowebinar.com/register/2617328485318026336" target="_blank">Zum Webinar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

{/* Start PRicing Table Area  */}
<div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <object height={80} data="/assets/images/webinar/GraphQL_Logo.svg" type="image/svg+xml">
                                                <img src="/assets/images/service/service-02.jpg" />
                                            </object>
                                            <h4 className="title">GraphQL Workshop</h4>
                                            <div className="pricing">
                                                <span className="price">49</span>
                                                <span className="subtitle">EUR</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCalendar /> Datum: SA, 11.03.2023</li>
                                                <li><FiWatch /> 11:00 - 13:00 Uhr</li>
                                                <li><FiClock /> Dauer: 2 Stunden</li>
                                                <li><FiBarChart /> Level = Anfänger</li>
                                                <li><FiTarget /> Zielgruppe = Entwickler, Software Architekte</li>                                                

                                            </ul>
                                            <br/>
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Praktische Übungen zu GraphQL</li>
                                                <li><FiCheck /> GraphQL Backend erstellen</li>
                                                <li><FiCheck /> GraphQL abfragen</li>
                                                <li><FiCheck /> Blazor Frontend (UI)</li>
                                                <li><FiCheck /> Filtering, Paging, Sorting im UI</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-header">
                                            <h4 className="title">Speaker</h4>
                                            <img className="w-600" src="/assets/images/webinar/serkan-rounded.png" alt="Speaker"/>                                            
                                            <div className="pricing">
                                                <span className="subtitle"><a href="/about">Serkan Uslubas</a></span>
                                            </div>                                            
                                        </div>
                                        
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://register.gotowebinar.com/register/8892448053446571861" target="_blank">Zum Workshop</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <object height={80} data="/assets/images/webinar/GraphQL_Logo.svg" type="image/svg+xml">
                                                <img src="/assets/images/service/service-02.jpg" />
                                            </object>
                                            <h4 className="title">GraphQL Workshop</h4>
                                            <div className="pricing">
                                                <span className="price">49</span>
                                                <span className="subtitle">EUR</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCalendar /> Datum: SA, 18.03.2023</li>
                                                <li><FiWatch /> 11:00 - 13:00 Uhr</li>
                                                <li><FiClock /> Dauer: 2 Stunden</li>
                                                <li><FiBarChart /> Level = Anfänger</li>
                                                <li><FiTarget /> Zielgruppe = Entwickler, Software Architekte</li>                                                

                                            </ul>
                                            <br/>
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Praktische Übungen zu GraphQL</li>
                                                <li><FiCheck /> GraphQL Backend erstellen</li>
                                                <li><FiCheck /> GraphQL abfragen</li>
                                                <li><FiCheck /> Blazor Frontend (UI)</li>
                                                <li><FiCheck /> Filtering, Paging, Sorting im UI</li>
                                            </ul>

                                        </div>
                                        <div className="pricing-header">
                                            <h4 className="title">Speaker</h4>
                                            <img className="w-600" src="/assets/images/webinar/serkan-rounded.png" alt="Speaker"/>                                            
                                            <div className="pricing">
                                                <span className="subtitle"><a href="/about">Serkan Uslubas</a></span>
                                            </div>                                            
                                        </div>
                                        
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://register.gotowebinar.com/register/8570634194136108378" target="_blank">Zum Workshop</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Counterup Area */}
            <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
            
                <div className="container">
                    <CounterOne />
                </div>
            </div>
            {/* End Counterup Area */}            

            {/* Start Service Area */}
            <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}








                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Webinar;