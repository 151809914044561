import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck } from "react-icons/fi";



const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--36',
        category: '',
        title: 'Webinare',
        description: 'Wir bieten interaktive und informative Schulungen an, die Ihnen helfen, fortgeschrittene Techniken zu erlernen und diese effektiv in verschiedenen Projekten anzuwenden.',
        buttonText: 'Zu den Webinaren',
        buttonLink: '/webinar'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--34',
        category: '',
        title: 'Software Development',
        description: 'Effiziente Lösungen durch maßgeschneiderte Software-Entwicklung.',
        buttonText: 'Zu den Webinaren',
        buttonLink: '/webinar'
    },
    
]

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Software Development',
        description: 'Wir setzen modernste Technologien ein, um benutzerfreundliche, skalierbare und zuverlässige Software zu entwickeln, die Ihnen dabei hilft, Ihre Geschäftsziele zu erreichen. '
    },
    {
        icon: <FiLayers />,
        title: 'Webinare',
        description: 'Schauen Sie sich jetzt unser aktuelles Webinarangebot an und verbessern Sie Ihre Fähigkeiten und Karriereaussichten durch das Lernen von den Besten.'
    },
    {
        icon: <FiUsers />,
        title: 'Workshops',
        description: 'Workshops bieten Ihnen die Möglichkeit, die in unseren Webinaren erworbenen Kenntnisse in die Praxis umzusetzen. Melden Sie sich jetzt an und lassen Sie uns gemeinsam Ihr Wissen vertiefen und ausbauen!'
    },
]

class Home extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){


        return(
            <Fragment> 
                <Helmet pageTitle="Corporate Business" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                

             {/* Start Service Area */}
             <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
 

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Home;