import React, { Component } from "react";
import {FaMap ,FaXing ,FaGofore , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaXing /> , Text:'XING', link: 'https://www.xing.com/profile/Serkan_Uslubas'},
    {Social: <FaLinkedinIn />, Text:'LinkedIn', link: 'https://www.linkedin.com/in/serkan-uslubas-60783541/'},
    {Social: <FaMap />, Text:'freelancermap', link: 'https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/33809-profil-serkan-uslubas-softwareentwickler.html'},
    {Social: <FaGofore />, Text:'GULP' , link: 'https://www.gulp.de/gulp2/g/spezialisten/profil/uslubas'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's Webinar</h2>
                                        <a className="rn-button-style--2" href="https://register.gotowebinar.com/register/2617328485318026336" target="_blank">
                                            <span>Zum GraphQL Webinar (04.03.2023)</span>
                                        </a>     
                                        <br/>
                                        <a className="rn-button-style--2" href="https://register.gotowebinar.com/register/8892448053446571861" target="_blank">
                                            <span>Zum GraphQL Workshop (11.03.2023)</span>
                                        </a>     
                                        <br/>
                                        <a className="rn-button-style--2" href="https://register.gotowebinar.com/register/8570634194136108378" target="_blank">
                                        <span>Zum GraphQL Workshop (18.03.2023)</span>
                                        </a>     
                                        <br/>                                   
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/impressum">Impressum</a></li>
                                                    <li><a href="/privacy">Datenschutzerklärung</a></li>
                                                    <li><a href="/webinar">Webinare</a></li>
                                                </ul>
                                                <br />
                                                <br />
                                                <img className="w-600" src="/assets/images/footer/logo2.png"/>                                                 
                                                <h4>dotnet factory</h4>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                            <img className="w-600" src="/assets/images/webinar/serkan-rounded.png"/> 
                                            <br />
                                            <br />   
                                            <h4><a href="/about">Serkan Uslubas</a></h4>

                                                <div className="social-share-inner">
                                                    <ul className="social-share">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><nobr><a href={`${val.link}`}  target="_blank">{val.Social}   &nbsp; &nbsp;&nbsp;&nbsp;{val.Text}</a></nobr></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2023 dotnet-factory. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;