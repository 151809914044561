import React, { Component } from "react";

const ServiceList = [
    {
        icon: '2023',
        title: 'Flaschenpost SE',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend.'
    },
    {
        icon: '2020-2022',
        title: 'RWE Renewables GmbH',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Verschiedene Projekte. Training von junior Entwicklern.'
    },
    {
        icon: '2018-2020',
        title: 'innogy SE',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Verschiedene Projekte. Training von junior Entwicklern.'
    },
    {
        icon: '2015-2018',
        title: 'RWE IT GmbH',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Verschiedene Projekte.'
    },
    {
        icon: '2014-2015',
        title: 'Arvato Systems GmbH',
        description: 'Software Entwicklung Backend & Frontend.'
    },    
    {
        icon: '2014',
        title: 'ERGO Group AG',
        description: 'Software Entwicklung Frontend bei Kapazitätsengpass.'
    },    
    {
        icon: '2013-2014',
        title: 'FRIEDRICH KOCKS GmbH & Co KG',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Verschiedene Projekte. Training von Mitarbeitern.'
    },    
    {
        icon: '2012-2013',
        title: 'JENOPTIK Robot GmbH',
        description: 'Software Entwicklung Backend & Frontend. Auslandeinsatz (Kuwait)'
    },    
    {
        icon: '2010-2012',
        title: 'Volkswagen AG',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Verschiedene Projekte.'
    },    
    {
        icon: '2009-2010',
        title: 'Nash Technologies GmbH',
        description: 'Software-Architektur und -Entwicklung Backend & Frontend. Training von Mitarbeitern.'
    },
    {
        icon: '2008-2009',
        title: 'Bassier, Bergmann & Kindler Group GmbH',
        description: 'Software Entwicklung Backend & Frontend. Verschiedene Projekte.'
    },    

]

class ReferenceElement extends Component{
    render(){
        return(
            <React.Fragment>
                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Referenzen</h2>
                                    <p>Übersicht einiger Projekte</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
            </React.Fragment>
        )
    }
}
export default ReferenceElement;